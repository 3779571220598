
<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.stop.prevent="updatePassword">
                  <h1>Forgot Password</h1>
                  <!-- <span  v-if="isLoggedIn">{{ redirect()}}</span> -->
                  <p class="text-muted">Forgot Password</p>
                  <div>
                    <div
                      v-if="errorMessage != null"
                      class="alert alert-danger"
                      role="alert"
                    >
                      {{ errorMessage }}
                    </div>
                  </div>
                  <input
                    type="password"
                    v-model="details.password"
                    placeholder="New Password"
                    id="email12"
                    name="pas"
                    class="form-control mb-2"
                    required
                  />

                  <template v-if="v$.details.password.$dirty">
                    <div
                      v-for="error of v$.details.password.$silentErrors"
                      :key="error.$message"
                    >
                      <div class="red">
                        {{ error.$message }}
                      </div>
                    </div>
                  </template>
                  <input
                    type="password"
                    v-model="details.confirmpwd"
                    placeholder="Confirm Password Password"
                    name="cpas"
                    class="form-control mb-2"
                    required
                  />
                  <span
                    class="red"
                    v-if="details.confirmpwd !== details.password"
                  >
                    Password Not Match
                  </span>

                  <CRow>
                    <CCol col="6" class="text-left">
                      <b-button type="submit" variant="primary"
                        >Submit</b-button
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>ExpoDataBox</h2>
                <p>
                  Expand your business with the great features provided to our
                  clients.
                </p>
              
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { required, minLength, between, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  name: "login",

  data() {
    return {
      errorMessage123: null,
      errorMessage12345: null,
      showDismissibleAlert: false,
      showDismissibleAlert123: false,
      errorMessage: null,
      message: "",
      selected: null,
      validationErrors: null,
      options: [
        { value: null, text: "Please select an option" },
        { value: "india", text: "India" },
        { value: "United State", text: "United State" },
      ],
      details: {
        password: null,
        confirmpwd: null,
        param: null,
      },
    };
  },
  validations() {
    return {
      details: {
        password: {
          required,
          minLength: minLength(8),
        },
        confirmpwd: {
          required,
        },
      },
    };
  },

  computed: {
    ...mapGetters(["errors", "user"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");
    },
  },
  mounted() {
    this.$store.commit("setErrors", {});
  },
  setup() {
    return { v$: useVuelidate() };
  },
  methods: {
    ...mapActions(["UpdateForgotPassword"]),
    ...mapMutations(["setErrors"]),
    updatePassword() {
      this.v$.details.$touch();
      if (this.v$.details.$error) {
        return;
      } else {
        this.details.param = this.$route.params.id;
        this.UpdateForgotPassword(this.details).then((response) => {
          let message = "Now login with your updated password";
          let title = "Password Updated Successfully";
          let type = "success";
          this.$alert(message, title, type).then(() => {
        window.location.href = "/";
      });
        });
      }
    },
    redirect() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.red {
  color: red;
}
</style>